import {Link, Outlet} from 'react-router-dom';

import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import {NavigateBeforeSharp, NavigateNextSharp} from '@mui/icons-material';

import {ContestViewData} from '../../../api/responses/ContestViewResponseTypes';
import {formatDate} from '../../../utils/date-utils';
import SubmissionStatus from '../utils/SubmissionStatus';
import {Color, Problem} from '../../../types';
import {ContestSubmission} from '../../../types/entities/ContestSubmission';

type SubmissionsTableProps = {
  submissions: ContestSubmission[];
  problems: {[id: number]: Problem} | undefined;
  searchParams: URLSearchParams;
  pagination: {
    prevPage: () => void;
    nextPage: () => void;
    offset: number;
    limit: number;
  };
  filterByProblem: (problemId: number) => void;
  contest: ContestViewData;
  groupContest: string | undefined;
  modalRoute: (
    contestId: number,
    groupContestId: string | undefined,
    submissionId: number,
    searchParams: URLSearchParams
  ) => string;

  filterByUser?: (userId: number) => void;
};

function SubmissionsTable({
  submissions,
  problems,
  searchParams,
  pagination,
  filterByProblem,
  contest,
  groupContest,
  modalRoute,
  filterByUser,
}: SubmissionsTableProps) {
  const theme = useTheme();

  return (
    <>
      {submissions.length && problems ? (
        <>
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: 650,
                '& .MuiTableRow-root:hover': {
                  backgroundColor: theme.palette.card.main,
                },
                '& .MuiTableCell-root': {
                  borderColor: theme.palette.card.light,
                  color: theme.palette.card.contrastText,
                },
                '& .MuiTableCell-head': {
                  backgroundColor: theme.palette.card.light,
                  fontSize: '17px',
                },
                backgroundColor: theme.palette.card.dark,
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Problem</TableCell>
                  {filterByUser && <TableCell>Name</TableCell>}
                  <TableCell>Status</TableCell>
                  <TableCell>Points</TableCell>
                  <TableCell>Correctness</TableCell>
                  <TableCell>Submitted at</TableCell>
                  <TableCell>During contest</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {submissions.map((submission, idx) => (
                  <>
                    <TableRow key={`contest-sub-${idx}`} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                      <TableCell component="th" scope="row">
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            filterByProblem(submission.problemId);
                          }}
                        >
                          {problems[submission.problemId]?.title}
                        </div>
                      </TableCell>
                      {filterByUser && (
                        <TableCell>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              filterByUser(submission.userId);
                            }}
                          >
                            {submission.userFirstName} {submission.userLastName}
                          </div>
                        </TableCell>
                      )}
                      <TableCell>
                        <SubmissionStatus
                          compiled={submission.result.compiled}
                          maxGrade={
                            submission.result.results != null &&
                            submission.result.results.every((result) => result.status === 'PASSED')
                          }
                        />
                      </TableCell>
                      <TableCell>{submission.grade}</TableCell>
                      <TableCell>
                        {submission.result.results != null
                          ? `${
                              (submission.result.results.filter((result) => result.status === 'PASSED').length /
                                submission.result.results.length) *
                              100
                            }%`
                          : '-'}
                      </TableCell>
                      <TableCell>{formatDate(submission.date)}</TableCell>
                      <TableCell>
                        {new Date(submission.date).getTime() > new Date(contest.start_date).getTime() &&
                        new Date(submission.date).getTime() < new Date(contest.end_date).getTime()
                          ? 'Yes'
                          : 'No'}
                      </TableCell>
                      <TableCell>
                        <Link
                          to={modalRoute(contest.id, groupContest, submission.id, searchParams)}
                          replace={true}
                          style={{textDecoration: 'none'}}
                        >
                          <Button size="small" variant="contained" color={Color.SECONDARY}>
                            View
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div>
            <IconButton
              color="primary"
              component="span"
              sx={{color: 'white'}}
              onClick={pagination.prevPage}
              disabled={pagination.offset === 0}
            >
              <NavigateBeforeSharp fontSize="large" />
            </IconButton>

            <IconButton
              color="primary"
              component="span"
              sx={{color: 'white'}}
              onClick={pagination.nextPage}
              disabled={submissions.length < pagination.limit}
            >
              <NavigateNextSharp fontSize="large" />
            </IconButton>
          </div>
          <Outlet context={{submissions, problems}} />
        </>
      ) : (
        <div>
          <h2>No submissions found 😕</h2>
        </div>
      )}
    </>
  );
}

export default SubmissionsTable;
