import {useContext, useEffect, useReducer, useState} from 'react';
import Select from 'react-select';

import {
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import {Clear} from '@mui/icons-material';

import {AllAuthorModulesResponse, AuthorEntry} from '../../../../api/responses/AuthorsResponseTypes';
import {request, URLs} from '../../../../api';
import AppContext from '../../../../context/AppContext';
import {ModuleType} from '../../../../types/entities/ModuleType';
import {dropdownStylesConfig} from '../../../../components/form/dropdown/Dropdown';
import {contestsPaginationStyle} from '../../../contests/contests-table/contest-table-styles';

type AuthorModulesManagementProps = {
  author: AuthorEntry;
  isOpen: boolean;
  onClose: () => void;
};

function AuthorModulesManagement({author, isOpen, onClose}: AuthorModulesManagementProps) {
  const theme = useTheme();
  const {dispatchError} = useContext(AppContext);

  const dropdownStyles = dropdownStylesConfig();
  const [update, forceUpdate] = useReducer((x) => x + 1, 0);
  const [page, setPage] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [authorModules, setAuthorModules] = useState<AllAuthorModulesResponse>([]);
  const [allModules, setAllModules] = useState<Array<ModuleType>>([]);

  const [selectedModule, setSelectedModule] = useState<ModuleType | null>(null);

  const [moduleIdToDelete, setModuleIdToDelete] = useState<number | null>(null);

  const [confirmationText, setConfirmationText] = useState<string>('');

  useEffect(() => {
    request<{}, {}, Array<ModuleType>>(URLs.getAllModules, {
      method: 'GET',
      successCallback: (response) => {
        setAllModules(response);
        setIsLoading(false);
      },
      errorCallback: () => {
        dispatchError('There was an error. Please try again later.', '/staff/authors');
      },
    });
  }, []);

  useEffect(() => {
    request<{}, {}, AllAuthorModulesResponse>(URLs.getAuthorModules(author.authorId), {
      method: 'GET',
      successCallback: (response) => {
        setAuthorModules(response ?? []);
      },
      errorCallback: () => {
        dispatchError('There was an error. Please try again later.', '/staff/authors');
      },
    });
  }, [update]);

  const assignAuthorToModule = (moduleId: number) => {
    if (moduleId) {
      request<
        {},
        {
          authorId: number;
          moduleId: number;
        },
        {}
      >(URLs.assingAuthorToModule, {
        method: 'POST',
        body: {
          authorId: author.authorId,
          moduleId,
        },
        successCallback: () => {
          setSelectedModule(null);
          forceUpdate();
        },
        errorCallback: () => {
          dispatchError('There was an error. Please try again later.', '/staff/authors');
        },
      });
    }
  };

  const unassingAuthorFromModule = (moduleId: number) => {
    if (moduleId) {
      request<{}, {}, {}>(URLs.unassignAuthorFromModule(author.authorId, moduleId), {
        method: 'DELETE',
        successCallback: () => {
          setSelectedModule(null);
          forceUpdate();
        },
        errorCallback: () => {
          dispatchError('There was an error. Please try again later.', '/staff/authors');
        },
      });
    }
  };

  return (
    <div>
      <Modal open={isOpen} onClose={onClose}>
        <div className="flex flex-col bg-background-default top-2/4 left-2/4 w-[50%] h-[80%] p-4 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
          {isLoading ? (
            <div className="flex h-full justify-center items-center content-center">
              <CircularProgress color="success" size={68} />
            </div>
          ) : (
            <div className="flex flex-col items-center gap-4 py-3 px-5">
              <Typography variant="h4">{`${author.firstName} ${author.lastName}'s modules`}</Typography>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <Select
                  id="select-module"
                  placeholder="Select module"
                  onChange={(event) => {
                    const selectedId = (event as {value: string; label: string}).value;
                    const module = allModules.filter((module) => module.id === Number(selectedId))[0];
                    setSelectedModule(module);
                  }}
                  options={allModules
                    .map((module) => ({label: module.module_name, value: module.id}))
                    .filter((m) => authorModules.every((am) => am.moduleId != m.value))}
                  styles={dropdownStyles}
                />
                <Button
                  variant="contained"
                  size="small"
                  disabled={!selectedModule}
                  onClick={() => assignAuthorToModule(selectedModule!.id!)}
                >
                  Assign module to Author
                </Button>
              </div>
              <div className="w-full">
                <Table
                  sx={{
                    '& .MuiTableRow-root:hover': {
                      backgroundColor: 'transparent',
                    },
                    '& .MuiTableCell-root': {
                      borderColor: theme.palette.card.light,
                      color: theme.palette.card.contrastText,
                    },
                    backgroundColor: theme.palette.card.dark,
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell> No. </TableCell>
                      <TableCell> Module Name </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody style={{borderColor: theme.palette.card.dark}}>
                    {authorModules &&
                      authorModules.slice(page * 5, page * 5 + 5).map((module, index) => (
                        <TableRow key={module.moduleId}>
                          <TableCell> {index + 1} </TableCell>

                          <TableCell> {module.moduleName}</TableCell>

                          <TableCell>
                            <IconButton onClick={() => setModuleIdToDelete(module.moduleId)} color="warning">
                              <Clear />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                {authorModules && authorModules.length === 0 && (
                  <div className="flex justify-center py-3 bg-slate-600">
                    <Typography variant="h6">This author has no assigned modules</Typography>
                  </div>
                )}
                {authorModules && (
                  <div className="flex flex-row justify-end">
                    <TablePagination
                      sx={contestsPaginationStyle(theme)}
                      rowsPerPageOptions={[5]}
                      component="div"
                      count={authorModules.length}
                      rowsPerPage={5}
                      page={page}
                      onPageChange={(_, page) => setPage(page)}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
      {moduleIdToDelete && (
        <Modal
          open={moduleIdToDelete != null}
          onClose={() => {
            setModuleIdToDelete(null);
          }}
        >
          <div className="flex flex-col items-center gap-4 py-5 px-5 bg-background-default top-2/4 left-2/4 w-[40%] h-fit p-4 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
            <Typography variant="h4">Confirm action</Typography>
            <Typography variant="subtitle2">
              You are about to remove an author from a module. This action will prevent the author from accessing the
              module’s materials (such as Problems and Contests), including the ones where the author has explicit write
              rights. To proceed, please write the name of the module and click 'Confirm'.
            </Typography>

            <input
              className="w-full p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
              placeholder={allModules.find((am) => am.id === moduleIdToDelete)?.module_name}
            />

            <div className="flex flex-row gap-5 items-center justify-center">
              <Button
                variant="contained"
                color="warning"
                disabled={confirmationText !== allModules.find((am) => am.id === moduleIdToDelete)?.module_name}
                onClick={() => {
                  unassingAuthorFromModule(moduleIdToDelete);
                  setModuleIdToDelete(null);
                }}
              >
                Confrim
              </Button>
              <Button
                variant="contained"
                color="gridPrimary"
                onClick={() => {
                  setModuleIdToDelete(null);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default AuthorModulesManagement;
