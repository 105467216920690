import {useContext} from 'react';
import {useNavigate} from 'react-router';

import {Skeleton, TablePagination, Typography} from '@mui/material';

import AppContext from '../../context/AppContext';
import {PaginatedProblemResponse} from '../../api/responses/PaginatedProblemsResponseTypes';
import {Table} from '..';
import {stringsToColoredChips, toChips} from './Chips';
import {contestsPaginationStyle} from '../../pages/contests/contests-table/contest-table-styles';
import {theme} from '../../types/theme/Theme';

export type ProblemsTablePropsV2 = {
  problems: Array<PaginatedProblemResponse>;
  pageSize: number;
  page: number;
  setPage: (value: number) => void;
  setRowsPerPage: (value: number) => void;
  noProblems: number;
  isLoading: boolean;
};

export const ProblemsTableV2 = ({
  problems,
  pageSize,
  page,
  setPage,
  setRowsPerPage,
  noProblems,
  isLoading,
}: ProblemsTablePropsV2) => {
  const context = useContext(AppContext);

  const navigate = useNavigate();

  const loadProblem = (problemId: number) => (_event: any) => {
    navigate(`/problem/${problemId}`);
  };

  return (
    <div className="flex flex-col">
      {isLoading && (
        <div>
          <Skeleton animation="pulse" variant="rectangular" className="py-6 m-4" />
          <Skeleton animation="pulse" variant="rectangular" className="py-[19rem] m-4" />
        </div>
      )}
      {problems && !isLoading && (
        <>
          <Table
            onClick={loadProblem}
            rows={problems.map((problem: PaginatedProblemResponse) => ({
              id: problem.id,
              name: (
                <Typography variant="subtitle1">
                  {problem.title.length > 28 ? `${problem.title.substring(0, 28)}...` : problem.title}
                </Typography>
              ),
              languages:
                toChips('language', [...problem.languages.map((language) => context.languages[language.languageId])]) ||
                [],
              difficulty: toChips('difficulty', [problem.difficulty]),
              category:
                problem.categories?.length > 0
                  ? stringsToColoredChips(
                      problem.categories.map((category) => category.title),
                      true
                    )
                  : stringsToColoredChips(['Uncategorized']),
            }))}
          />
          <TablePagination
            sx={contestsPaginationStyle(theme)}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={noProblems}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={(event, page) => setPage(page)}
            onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
          />
        </>
      )}
    </div>
  );
};
