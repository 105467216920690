import Select from 'react-select';
import {useContext, useEffect, useReducer, useState} from 'react';

import {Button, Modal, Typography} from '@mui/material';

import {dropdownStylesConfig} from '../../../components/form/dropdown/Dropdown';
import {ModuleType} from '../../../types/entities/ModuleType';
import {request, URLs} from '../../../api';
import AppContext from '../../../context/AppContext';
import {RoleCode} from '../../../types/entities/Role';
import ModuleMembersView from './components/ModuleMembersView';

type UserMinimalInfo = {id: number; firstName: string; lastName: string; email: string; role: RoleCode};

function ModulesManagement() {
  const dropdownStyles = dropdownStylesConfig();
  const {user, dispatchError} = useContext(AppContext);

  const [update, forceUpdate] = useReducer((x) => x + 1, 0);

  const [allAvailableModules, setAllAvailableModules] = useState<Array<ModuleType>>([]);
  const [selectedModule, setSelectedModule] = useState<ModuleType | undefined>(undefined);

  const [allCourseAdmins, setAllCourseAdmins] = useState<Array<UserMinimalInfo> | undefined>(undefined);
  const [allAuthors, setAllAuthors] = useState<Array<UserMinimalInfo> | undefined>(undefined);
  const [allTeacherAuthors, setAllTeacherAuthors] = useState<Array<UserMinimalInfo> | undefined>(undefined);
  const [memberIdToUnassign, setMemberIdToUnassign] = useState<number | undefined>(undefined);

  const [newCourseAdminEmail, setNewCourseAdminEmail] = useState<string>('');
  const [newAuthorEmail, setNewAuthorEmail] = useState<string>('');
  const [newTeacherAuthorEmail, setNewTeacherAuthorEmail] = useState<string>('');

  useEffect(() => {
    request<{}, {}, Array<ModuleType>>(URLs.getAllModules, {
      method: 'GET',
      successCallback: (response) => {
        setAllAvailableModules(response);
      },
      errorCallback: () => {
        dispatchError('There was an error. Please try again later.', '/staff/modules');
      },
    });
  }, [update]);

  useEffect(() => {
    if (selectedModule && selectedModule.id) {
      request<{}, {}, Array<UserMinimalInfo>>(URLs.allModuleMembers(selectedModule.id), {
        method: 'GET',
        successCallback: (response) => {
          setAllCourseAdmins(response.filter((r) => r.role == RoleCode.COURSE_ADMIN));
          setAllAuthors(response.filter((r) => r.role == RoleCode.AUTHOR));
          setAllTeacherAuthors(response.filter((r) => r.role == RoleCode.AUTHOR_TEACHER));
        },
        errorCallback: () => {
          dispatchError('There was an error. Please try again later.', '/staff/modules');
        },
      });
    }
  }, [selectedModule, update]);

  const onMemberAssign = (moduleId: number, userEmail: string) => {
    request<{}, {userEmail: string}, {}>(URLs.assignMemberToModule(moduleId), {
      method: 'POST',
      body: {
        userEmail,
      },
      successCallback: () => {
        forceUpdate();
      },
      errorCallback: (e: any) => {
        dispatchError(e.response.data.error ?? 'There was an error. Please try again later.', '/staff/modules');
      },
    });
  };

  const onCourseAdminUnassign = (moduleId: number, courseAdminId: number) => {
    request<{}, {}, {}>(URLs.unassignMemberFromModule(moduleId, courseAdminId), {
      method: 'DELETE',
      successCallback: () => {
        forceUpdate();
      },
      errorCallback: (e: any) => {
        dispatchError(e.response.data.error ?? 'There was an error. Please try again later.', '/staff/modules');
      },
    });
  };

  return (
    <>
      <div className="flex flex-col w-full h-full gap-2">
        <div className="flex flex-row items-center justify-between w-full">
          <div className="flex flex-row gap-2 items-center">
            <div className="flex w-96">
              <Select
                id="select-module"
                placeholder="Select module"
                onChange={(event) => {
                  const selectedId: string = (event as {value: string; label: string}).value;
                  const group = allAvailableModules.filter((m) => m.id === Number(selectedId))[0];
                  setSelectedModule(group);
                }}
                options={allAvailableModules.map((m) => ({label: m.module_name, value: m.id}))}
                styles={dropdownStyles}
              />
            </div>
          </div>
          {/* {!selectedModule && user?.role === Role.ADMIN && 'create new module pt admini'} */}
        </div>
        {selectedModule && (
          <ModuleMembersView
            allMembers={allCourseAdmins ?? []}
            newMemberEmailValue={newCourseAdminEmail}
            setNewMemberEmail={(email) => setNewCourseAdminEmail(email)}
            onNewMemberAssign={(email) => onMemberAssign(selectedModule.id!, email)}
            setMemberToUnassignCallback={(memberId) => setMemberIdToUnassign(memberId)}
            title="Course Admin"
          />
        )}

        {selectedModule && (
          <ModuleMembersView
            allMembers={allAuthors ?? []}
            newMemberEmailValue={newAuthorEmail}
            setNewMemberEmail={(email) => setNewAuthorEmail(email)}
            onNewMemberAssign={(email) => onMemberAssign(selectedModule.id!, email)}
            setMemberToUnassignCallback={(memberId) => setMemberIdToUnassign(memberId)}
            title="Author"
          />
        )}
        {selectedModule && (
          <ModuleMembersView
            allMembers={allTeacherAuthors ?? []}
            newMemberEmailValue={newTeacherAuthorEmail}
            setNewMemberEmail={(email) => setNewTeacherAuthorEmail(email)}
            onNewMemberAssign={(email) => onMemberAssign(selectedModule.id!, email)}
            setMemberToUnassignCallback={(memberId) => setMemberIdToUnassign(memberId)}
            title="Teacher Author"
          />
        )}
      </div>
      {memberIdToUnassign && selectedModule && (
        <Modal
          open={memberIdToUnassign != null}
          onClose={() => {
            setMemberIdToUnassign(undefined);
          }}
        >
          <div className="flex flex-col items-center gap-4 py-5 px-5 bg-background-default top-2/4 left-2/4 w-[40%] h-fit p-4 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
            <Typography variant="h4">Confirm action</Typography>
            <Typography variant="subtitle2">
              {Number(user?.id) === memberIdToUnassign
                ? `You are about to unassign yourself from module '${selectedModule.module_name}'. You will lose access to all settings and materials related to this module, are you sure you want to continue?`
                : `You are about to unassign ${allCourseAdmins?.find((ca) => ca.id == memberIdToUnassign)
                    ?.firstName} from module '${
                    selectedModule.module_name
                  }'.  That member will lose access to all settings and materials related to this module, are you sure you want to continue?`}
            </Typography>
            <div className="flex flex-row gap-5 items-center justify-center">
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  onCourseAdminUnassign(selectedModule.id!, memberIdToUnassign);
                  setMemberIdToUnassign(undefined);
                }}
              >
                Confrim
              </Button>
              <Button
                variant="contained"
                color="gridPrimary"
                onClick={() => {
                  setMemberIdToUnassign(undefined);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default ModulesManagement;
