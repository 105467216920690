import {useContext, useEffect, useReducer, useState} from 'react';

import {
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import {Clear} from '@mui/icons-material';

import {AuthorEntry, AuthorProblemsResponse} from '../../../../api/responses/AuthorsResponseTypes';
import {request, URLs} from '../../../../api';
import AppContext from '../../../../context/AppContext';
import {theme} from '../../../../types/theme/Theme';
import {contestsPaginationStyle} from '../../../contests/contests-table/contest-table-styles';
import {ModuleType} from '../../../../types/entities/ModuleType';

type AuthorProblemsManagementProps = {
  author: AuthorEntry;
  isOpen: boolean;
  onClose: () => void;
};

function AuthorProblemsManagement({author, isOpen, onClose}: AuthorProblemsManagementProps) {
  const {dispatchError} = useContext(AppContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [update, forceUpdate] = useReducer((x) => x + 1, 0);
  const [page, setPage] = useState<number>(0);

  const [allModules, setAllModules] = useState<Array<ModuleType>>([]);

  const [authorProblems, setAuthorProblems] = useState<AuthorProblemsResponse>([]);
  const [problemIdToUnassign, setProblemIdToUnassign] = useState<number | null>();

  useEffect(() => {
    request<{}, {}, Array<ModuleType>>(URLs.getAllModules, {
      method: 'GET',
      successCallback: (response) => {
        setAllModules(response);
        setIsLoading(false);
      },
      errorCallback: () => {
        dispatchError('There was an error. Please try again later.', '/staff/authors');
      },
    });
  }, []);

  useEffect(() => {
    request<{}, {}, AuthorProblemsResponse>(URLs.getAuthorProblems(author.authorId), {
      method: 'GET',
      successCallback: (response) => {
        setAuthorProblems(response ?? []);
        setIsLoading(false);
      },
      errorCallback: () => {
        dispatchError('There was an error. Please try again later.', '/staff/authors');
      },
    });
  }, [update]);

  const unassingAuthorFromProblem = (problemId: number) => {
    if (problemId) {
      request<{}, {}, {}>(URLs.unassignAuthorFromProblem(problemId, author.authorId), {
        method: 'DELETE',
        successCallback: () => {
          forceUpdate();
        },
        errorCallback: () => {
          dispatchError('There was an error. Please try again later.', '/staff/authors');
        },
      });
    }
  };

  return (
    <div>
      <Modal open={isOpen} onClose={onClose}>
        <div className="flex flex-col bg-background-default top-2/4 left-2/4 w-[50%] h-fit min-h-[60%] p-4 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
          {isLoading ? (
            <div className="flex h-full justify-center items-center content-center">
              <CircularProgress color="success" size={68} />
            </div>
          ) : (
            <div className="flex flex-col items-center gap-4 py-3 px-5">
              <Typography variant="h4">{`${author.firstName} ${author.lastName}'s Problems`}</Typography>
              <div className="flex flex-col w-full">
                <Table
                  sx={{
                    '& .MuiTableRow-root:hover': {
                      backgroundColor: 'transparent',
                    },
                    '& .MuiTableCell-root': {
                      borderColor: theme.palette.card.light,
                      color: theme.palette.card.contrastText,
                    },
                    backgroundColor: theme.palette.card.dark,
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell> No. </TableCell>
                      <TableCell> Problem Name </TableCell>
                      <TableCell> Module Name </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody style={{borderColor: theme.palette.card.dark}}>
                    {authorProblems &&
                      authorProblems.slice(page * 5, page * 5 + 5).map((authorProblem, index) => (
                        <TableRow key={authorProblem.problemId}>
                          <TableCell> {index + 1} </TableCell>

                          <TableCell> {authorProblem.problemName}</TableCell>
                          <TableCell>
                            {allModules.find((am) => am.id == authorProblem.moduleId)?.module_name ?? '-'}
                          </TableCell>

                          <TableCell>
                            <IconButton onClick={() => setProblemIdToUnassign(authorProblem.problemId)} color="warning">
                              <Clear />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                {authorProblems && authorProblems.length === 0 && (
                  <div className="flex justify-center py-3 bg-slate-600">
                    <Typography variant="h6">This author has no assigned problems</Typography>
                  </div>
                )}
                {authorProblems && (
                  <div className="flex flex-row justify-end">
                    <TablePagination
                      sx={contestsPaginationStyle(theme)}
                      rowsPerPageOptions={[5]}
                      component="div"
                      count={authorProblems.length}
                      rowsPerPage={5}
                      page={page}
                      onPageChange={(_, page) => setPage(page)}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
      {problemIdToUnassign && (
        <Modal
          open={problemIdToUnassign != null}
          onClose={() => {
            setProblemIdToUnassign(null);
          }}
        >
          <div className="flex flex-col items-center gap-4 py-5 px-5 bg-background-default top-2/4 left-2/4 w-[40%] h-fit p-4 absolute translate-x-[-50%] translate-y-[-50%] overflow-y-scroll">
            <Typography variant="h4">Confirm action</Typography>
            <Typography variant="subtitle2">
              You are about to unassign an author from a problem. This action will remove the author's ability to read
              or write to this specific problem. However, the author will retain read access if they are still assigned
              to the module that includes this problem.
            </Typography>
            <div className="flex flex-row gap-5 items-center justify-center">
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  unassingAuthorFromProblem(problemIdToUnassign);
                  setProblemIdToUnassign(null);
                }}
              >
                Confrim
              </Button>
              <Button
                variant="contained"
                color="gridPrimary"
                onClick={() => {
                  setProblemIdToUnassign(null);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default AuthorProblemsManagement;
