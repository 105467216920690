import {useContext, useEffect, useReducer, useState} from 'react';

import {
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import {AuthorEntry} from '../../api/responses/AuthorsResponseTypes';
import AppContext from '../../context/AppContext';
import {Color} from '../../types';
import {Pattern} from '../../validation/Pattern';
import {contestsPaginationStyle} from '../../pages/contests/contests-table/contest-table-styles';
import ConfirmationModal from '../modal/ConfirmationModal';

type AuthorsManagementProps = {
  loadAuthors: (callback: () => void) => Promise<Array<AuthorEntry>>;
  assignAuthor: (authorEmail: string, callback: () => void) => void;
  unassignAuthor: (authorId: number, callback: () => void) => void;
};

function AuthorsManagement({loadAuthors, assignAuthor, unassignAuthor}: AuthorsManagementProps) {
  const theme = useTheme();
  const {user} = useContext(AppContext);
  const [update, forceUpdate] = useReducer((x) => x + 1, 0);

  const [authorIdToUnassign, setAuthorIdToUnassign] = useState<number | null>(null);
  const [page, setPage] = useState<number>(0);

  const [authors, setAuthors] = useState<Array<AuthorEntry> | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const [email, setEmail] = useState<string>('');

  const load = async () => {
    setLoading(true);
    setAuthors(
      await loadAuthors(() => {
        setLoading(false);
      })
    );
  };

  useEffect(() => {
    load();
  }, [update]);

  return (
    <>
      <div className="flex flex-col px-12 py-6 items-center gap-4">
        <Typography variant="h4">Manage Authors</Typography>
      </div>
      <div className="flex flex-row gap-4 py-3 px-6 items-center w-full">
        <input
          type="email"
          value={email}
          className="w-full p-[15px] bg-card-main rounded outline-none text-white border-none"
          placeholder="Add author by email..."
          onChange={(e: React.FormEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
        />

        <Button
          disabled={!Pattern.emailRegex.test(email)}
          color={Color.SECONDARY}
          variant="contained"
          size="small"
          onClick={() => {
            // setButtonDisabled(true);
            assignAuthor(email, () => forceUpdate());
          }}
          sx={{
            '&.Mui-disabled': {
              background: theme.palette.primary.dark,
              color: theme.palette.gridPrimary.contrastText,
            },
          }}
        >
          Assign selected author
        </Button>
      </div>
      {!loading && authors && (
        <div className="px-6">
          <Table
            sx={{
              '& .MuiTableRow-root:hover': {
                backgroundColor: 'transparent',
              },
              '& .MuiTableCell-root': {
                borderColor: theme.palette.card.light,
                color: theme.palette.card.contrastText,
              },
              backgroundColor: theme.palette.card.dark,
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell> No. </TableCell>
                <TableCell> Name </TableCell>
                <TableCell> Email </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody style={{borderColor: theme.palette.card.dark}}>
              {authors &&
                authors.slice(page * 5, page * 5 + 5).map((author) => (
                  <TableRow key={author.authorId}>
                    <TableCell> {authors.findIndex((a) => a.authorId == author.authorId) + 1} </TableCell>

                    <TableCell> {`${author.firstName} ${author.lastName}`} </TableCell>
                    <TableCell> {author.email} </TableCell>

                    <TableCell>
                      <IconButton onClick={() => setAuthorIdToUnassign(author.authorId)} color="warning">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {authors && authors.length === 0 && (
            <div className="flex justify-center py-3 bg-slate-600">
              <Typography variant="h6">This problem has no authors, please start by adding one.</Typography>
            </div>
          )}
          {authors && authors.length > 0 && (
            <div className="flex flex-row justify-end">
              <TablePagination
                sx={contestsPaginationStyle(theme)}
                rowsPerPageOptions={[5]}
                component="div"
                count={authors.length}
                rowsPerPage={5}
                page={page}
                onPageChange={(_, page) => setPage(page)}
              />
            </div>
          )}
        </div>
      )}
      {loading && (
        <div className="flex flex-row justify-center items-center">
          <CircularProgress color="success" />
        </div>
      )}
      {unassignAuthor && authorIdToUnassign && (
        <ConfirmationModal
          message={
            user?.id == authorIdToUnassign
              ? "You are about to unassign yourself, you'll no longer have read and write privileges on this material, you might keep read privileges if you are assigned to the same module as this material. Are you sure you want to proceed?"
              : 'If you unassign an author, he will no longer have read and write privileges on this material, he might keep his read privilege if he is assigned to the same module as this material. Are you sure you want to proceed?'
          }
          onClose={() => setAuthorIdToUnassign(null)}
          onAccept={() => {
            unassignAuthor(authorIdToUnassign, () => forceUpdate());
            setAuthorIdToUnassign(null);
          }}
        />
      )}
    </>
  );
}

export default AuthorsManagement;
