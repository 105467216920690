import {SetStateAction} from 'react';

import {Typography, Button} from '@mui/material';

import {Color} from '../../../../types';
import {theme} from '../../../../types/theme/Theme';
import ModuleMebmersTable from '../tables/ModuleMembersTable';
import {Pattern} from '../../../../validation/Pattern';

type UserMinimalInfo = {id: number; firstName: string; lastName: string; email: string};

type ModuleMembersViewProps = {
  newMemberEmailValue: string;
  setNewMemberEmail: (value: SetStateAction<string>) => void;
  onNewMemberAssign: (email: string) => void;
  setMemberToUnassignCallback: (memberId: number) => void;
  allMembers: Array<UserMinimalInfo>;
  title: string;
};

function ModuleMembersView({
  newMemberEmailValue,
  setNewMemberEmail,
  onNewMemberAssign,
  setMemberToUnassignCallback,
  allMembers,
  title,
}: ModuleMembersViewProps) {
  return (
    <div className="flex flex-col gap-4 mt-4">
      <div>
        <div className="flex flex-row items-center justify-between">
          <Typography variant="h3">{title}s</Typography>
          <div className="flex items-center gap-3">
            <div className="w-80">
              <input
                className="w-full p-[15px] h-14 rounded outline-none text-white border-none bg-inputBox-backgroundColor"
                placeholder="Enter email..."
                value={newMemberEmailValue}
                onChange={(e) => setNewMemberEmail(e.target.value)}
                type="email"
                autoComplete="off"
              />
            </div>
            <Button
              disabled={!Pattern.emailRegex.test(newMemberEmailValue)}
              color={Color.SECONDARY}
              variant="contained"
              size="small"
              onClick={() => {
                onNewMemberAssign(newMemberEmailValue);
                setNewMemberEmail('');
              }}
              sx={{
                width: '191px',
                '&.Mui-disabled': {
                  background: theme.palette.primary.dark,
                  color: theme.palette.gridPrimary.contrastText,
                },
              }}
            >
              Assign {title}
            </Button>
          </div>
        </div>
        <ModuleMebmersTable
          users={allMembers}
          onUnassignCallback={(memberId) => setMemberToUnassignCallback(memberId)}
        />
      </div>
    </div>
  );
}

export default ModuleMembersView;
