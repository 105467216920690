import Select from 'react-select';
import {useEffect, useState} from 'react';

import {IconButton, Typography} from '@mui/material';
import {Delete} from '@mui/icons-material';

import {CodeEditor} from '../../code-editor/CodeEditor';
import {dropdownStylesConfig} from '../dropdown/Dropdown';
import {languageOptions} from '../../../types/entities/Language';
import {InputProps as Props} from '../../../types/';
import {Error} from '../error/Error';

function ProblemLanguages(props: Props) {
  const dropdownStyles = dropdownStylesConfig();

  const [languageToAdd, setLanguageToAdd] = useState<{
    value: number;
    label: string;
  } | null>(null);

  const [selectedLanguages, setSelectedLanguages] = useState<
    Array<{
      languageId: number;
      skeleton: string;
    }>
  >(props.languages ?? []);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(null, selectedLanguages);
    }
  }, [selectedLanguages]);

  const onLanguageAdd = (languageId: number) => {
    if (languageId === null || selectedLanguages.find((language) => language.languageId === languageId)) {
      return;
    }

    setSelectedLanguages((prev) => [
      ...prev,
      {
        languageId,
        skeleton: '',
      },
    ]);
    setLanguageToAdd(null);
  };

  const onLanguageRemove = (languageId: number | null) => {
    setSelectedLanguages((prev) => prev.filter((language) => language.languageId !== languageId));
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <Typography variant="h6">{'Languages'}</Typography>
        <div className="">
          {selectedLanguages?.map((language) => (
            <div key={language.languageId}>
              <div className="flex flex-row justify-between items-center">
                <div className="font-medium text-lg text-center">
                  {languageOptions.find((option) => option.languageId === language.languageId)?.label ??
                    'Unknown language'}
                </div>

                <IconButton color="error" size="large" onClick={() => onLanguageRemove(language.languageId)}>
                  <Delete />
                </IconButton>
              </div>
              <CodeEditor
                code={language.skeleton}
                height={300}
                language={languageOptions.find((option) => option.languageId === language.languageId)?.value ?? ''}
                onChange={(newValue) =>
                  setSelectedLanguages(
                    selectedLanguages.map((lang) =>
                      lang.languageId === language.languageId
                        ? {languageId: lang.languageId, skeleton: newValue ?? ''}
                        : lang
                    )
                  )
                }
              />
            </div>
          ))}
        </div>
        <div className="flex flex-row justify-end w-full gap-5 items-center">
          <Select
            styles={dropdownStyles}
            options={[
              ...languageOptions
                .map((option) => ({
                  value: option.languageId,
                  label: option.label,
                }))
                .filter((option) => !selectedLanguages.find((language) => language.languageId === option.value)),
            ]}
            placeholder="Select language"
            value={languageToAdd}
            onChange={(newValue) => setLanguageToAdd(newValue as {value: number; label: string})}
          />

          {/* (Eduard: Because of curr. impl. of Form.tsx, any button tag acts as submit button. To be changed: div to proper button tag <- after refactoring New/Edit Problem Modal to Formik */}
          {(languageToAdd && (
            <div
              onClick={() => onLanguageAdd(languageToAdd?.value as number)}
              className="px-5 py-3 text-center cursor-pointer text-white font-semibold bg-green-700 rounded-lg hover:bg-green-900 transition-all"
            >
              Add language
            </div>
          )) || (
            <div className="px-5 py-3 text-center text-white font-semibold bg-green-700 rounded-lg hover:bg-green-900 transition-all opacity-50">
              Add language
            </div>
          )}
        </div>
      </div>
      {props.error !== false && (
        <div className="mt-2">
          <Error id={props.id} message={props.error!} />
        </div>
      )}
    </>
  );
}

export default ProblemLanguages;
