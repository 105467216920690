import {useEffect, useState, useContext, useReducer} from 'react';
import {useNavigate} from 'react-router-dom';

import {EmojiEvents, Category as CategoryIcon} from '@mui/icons-material';
import {Button, Typography} from '@mui/material';

import {Form as FormComponent} from '..';
import AppContext from '../../context/AppContext';
import {CreateProblemRequest, sendGetRequest, sendRequest, URLs} from '../../api';
import {NewProblemProps as Props} from './NewProblem.props';
import {Test} from '../../types/entities/Test';
import {Category} from '../../types/entities/Category';
import {ModuleType} from '../../types/entities/ModuleType';
import ConfirmationModal from '../modal/ConfirmationModal';

export const Form = (props: Props) => {
  const navigate = useNavigate();

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const {dispatchError} = useContext(AppContext);
  const [errors] = useState<Record<string, any>>({});
  const [allCategories, setAllCategories] = useState<Array<Category>>();

  const [modules, setModules] = useState<Array<ModuleType>>([]);
  const [selectedModule, setSelectedModule] = useState<ModuleType | null>();
  const [selectedModuleToBe, setSelectedModuleToBe] = useState<ModuleType | undefined>();

  useEffect(() => {
    sendGetRequest('/category/all')
      .then((response) => {
        setAllCategories(response.data);
      })
      .catch((err) => {
        if (err) {
          if (err.response) {
            dispatchError(err.response.error);
          } else {
            dispatchError('There was an error. Please try again later');
          }
        }
      });
  }, []);

  useEffect(() => {
    sendGetRequest(URLs.getAllModules)
      .then((response) => {
        const modules: Array<ModuleType> = response.data;
        setModules(modules);
        if (props.moduleId) {
          setSelectedModule(modules.find((module) => module.id === props.moduleId));
        }
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            dispatchError(err.response.data.error);
          } else {
            dispatchError('There was an error. Please try again later');
          }
        }
      });
  }, []);

  const handleSubmit = async (data: any) => {
    // making sure we don't send data we don't need
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {difficulty, test, problemLanguages, ...others} = data;

    if (!selectedModule) {
      dispatchError('Please select a module, this choice is permanent and it cannot be changed afterwards.');
      return;
    }

    // Change this when we want to support private problems
    const DEFAULT_VISIBILITY = true;

    const request = {
      title: data.title,
      description: data.description,
      categories: data.categories,
      languages: problemLanguages,
      difficulty: difficulty ?? 'easy',
      tests: test ? test.map((test: Test) => ({...test, grade: parseInt(test.grade, 10)})) : [],
      visible: DEFAULT_VISIBILITY,
      module_id: selectedModule.id as number,
    } as CreateProblemRequest;

    await sendRequest(props.method, props.url, request)
      .then((response) => {
        if (response) {
          if (response.status === 200 || response.status === 201) {
            if (props.onSuccess) {
              props.onSuccess();
            }

            if (response.data?.id) {
              navigate(`/problem/${response.data?.id}`, {replace: true});
            }
          }
        }
        window.location.reload();
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            dispatchError(err.response.data.error);
          } else {
            dispatchError('There was an error. Please try again later');
          }
        }
      });
  };

  if (props.moduleId && !selectedModule) {
    return <></>;
  } // Eduard: Cheap fix until we refactor Forms

  return (
    <div className="flex flex-col justify-between p-10">
      <Typography align="center" variant="h3">
        {props.isEdit ? `Edit '${props.title}' problem` : 'Create a new problem'}
      </Typography>
      <FormComponent
        button={{
          className: 'mt-25 mb-10',
          label: 'Submit',
          onClick: handleSubmit,
        }}
        formClass="mt-25"
        fullWidth={true}
        inputs={[
          [
            {
              id: 'title',
              error: errors['title'],
              placeholder: 'Problem title',
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'You must include a title',
                  },
                ],
              },
              startAdornment: <EmojiEvents />,
              type: 'input',
              value: props.title,
            },
            {
              id: 'difficulty',
              error: errors['difficulty'],
              options: ['easy', 'medium', 'hard'],
              placeholder: 'Difficulty',
              rules: {},
              type: 'dropdown',
              value: props.difficulty,
            },
            {
              id: 'module-type',
              error: errors['module-type'],
              options: modules.map((module) => ({label: module.module_name, value: module.id})),
              placeholder: 'Module type',
              rules: {},
              type: 'unmanaged-dropdown',
              onChangex: (selected) => {
                const newSelectedModule = modules.find((module) => module.id == selected.value);
                if (newSelectedModule && newSelectedModule.id != selectedModule?.id) {
                  setSelectedModuleToBe(modules.find((module) => module.id == newSelectedModule.id));
                }
              },
              valuex: selectedModule ? {label: selectedModule.module_name, value: selectedModule.id} : null,
              disabled: selectedModule !== undefined,
              disabledTooltip: 'Once selected the module cannot be changed',
            },
          ],
          [
            {
              id: 'categories',
              error: errors['categories'],
              placeholder: 'Categories',
              rules: {},
              startAdornment: <CategoryIcon />,
              type: 'multiselect',
              selectedOptions: props.categories.map((category) => ({label: category.title, value: category.id})) ?? [],
              options: allCategories?.map((category) => ({label: category.title, value: category.id})) ?? [],
            },
          ],
          [
            {
              id: 'description',
              error: errors['description'],
              minRows: 2,
              placeholder: 'Description',
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'You must include a description',
                  },
                ],
              },
              type: 'markdown',
              value: props.description,
            },
          ],
          [
            {
              id: 'problemLanguages',
              type: 'problem-languages',
              error: errors['problemLanguagess'],
              placeholder: 'Languages',
              rules: {
                length: [
                  {
                    cond: 'greater',
                    value: 1,
                    message: 'You must include at least one language per problem',
                  },
                ],
              },
              languages: props.languages,
            },
          ],
          [
            {
              id: 'test',
              error: errors['test'],
              maxNoEntries: 10,
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'You must include atleast one test',
                  },
                ],
              },
              title: 'Tests',
              type: 'test',
              value: props.test,
            },
          ],
        ]}
      />
      {selectedModuleToBe && (
        <ConfirmationModal
          message="The problem's module cannot EVER be changed. Are you sure you want to continue?"
          onClose={() => {
            setSelectedModule(undefined);
            setSelectedModuleToBe(undefined);
            forceUpdate();
          }}
          onAccept={() => {
            setSelectedModule(selectedModuleToBe);
            setSelectedModuleToBe(undefined);
          }}
          acceptButton={
            <Button variant="contained" color="warning">
              Accept
            </Button>
          }
        />
      )}
    </div>
  );
};
