import {useContext, useState} from 'react';
import {useNavigate} from 'react-router';

import {Lock, LockOutlined, MailOutline, Person, ContactPhone} from '@mui/icons-material';
import {Divider, styled, Typography} from '@mui/material';

import AppContext from '../../../context/AppContext';
import {RegisterRequest} from '../../../api/Requests';
import {sendPostRequest, URLs} from '../../../api';
import {Form} from '../../../components';
import {breakpoints} from '../../../types';

const Container = styled('div')(() => ({
  boxSizing: 'border-box',
  marginTop: '3vh',
  padding: '7% 10% 4% 10%',
  width: '100%',

  [breakpoints.up_to_md.css]: {
    marginTop: '5%',
    padding: '10%',
  },
}));

export const RegisterForm = () => {
  const {dispatchError} = useContext(AppContext);

  const [errors, setErrors] = useState<Record<string, any>>({});
  const navigate = useNavigate();

  const handleSubmit = (data: any) => {
    const guest = data.guest;
    delete data.guest;

    const request = {
      ...data,
    } as RegisterRequest;

    sendPostRequest(URLs.register, request)
      .then((response) => {
        if (response.status === 201) {
          navigate('/login');
        }
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            dispatchError(err.response.data.error, '/');
          } else {
            dispatchError('There was an error. Please try again later', '/');
          }
        }
      });
  };

  return (
    <Container>
      <Typography sx={{paddingBottom: '30px'}} variant="h3">
        Create an account
      </Typography>
      <Divider />
      <Form
        button={{
          className: 'mt-10',
          label: 'Register',
          onClick: handleSubmit,
        }}
        formClass="mt-25"
        fullWidth={true}
        inputClass="mb-10"
        inputs={[
          [
            {
              id: 'firstName',
              error: errors['firstName'],
              placeholder: 'First name',
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'Nu ai introdus prenumele',
                  },
                ],
              },
              startAdornment: <Person />,
              type: 'input',
              autoComplete: 'given-name',
            },
            {
              id: 'lastName',
              error: errors['lastName'],
              placeholder: 'Last name',
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'Nu ai introdus numele',
                  },
                ],
              },
              startAdornment: <Person />,
              type: 'input',
              autoComplete: 'family-name',
            },
          ],
          [
            {
              id: 'email',
              error: errors['email'],
              placeholder: 'Email',
              rules: {
                pattern: [
                  {
                    message: 'Adresa de email invalida',
                    type: 'email',
                  },
                ],
              },
              startAdornment: <MailOutline />,
              type: 'input',
              autoComplete: 'email',
            },
            {
              id: 'phoneNumber',
              error: errors['phoneNumber'],
              placeholder: 'Phone Number',
              rules: {
                pattern: [
                  {
                    message: 'Numar de telefon invalid',
                    type: 'phoneNumber',
                  },
                ],
              },
              startAdornment: <ContactPhone />,
              type: 'input',
            },
          ],
          [
            {
              id: 'password',
              error: errors['password'],
              placeholder: 'Password',
              rules: {
                length: [
                  {
                    cond: 'between',
                    low: 6,
                    high: 30,
                    message: 'Parola trebuie sa aiba intre 6 si 30 de caractere',
                  },
                ],
                pattern: [
                  {
                    message: 'Parola nu trebuie sa contina spatii goale',
                    type: 'noWhiteSpaces',
                  },
                ],
              },
              startAdornment: <LockOutlined />,
              type: 'password-input',
              autoComplete: 'password',
            },
            {
              id: 'confirmedPassword',
              error: errors['confirmedPassword'],
              placeholder: 'Confirm password',
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'Nu ai introdus parola de confirmare',
                  },
                ],
              },
              startAdornment: <Lock />,
              type: 'password-input',
              autoComplete: 'password',
            },
          ],
        ]}
        splitRowsFrom="md"
      />
      <div style={{paddingTop: '20px', textAlign: 'center', width: '100%'}}>
        <Typography variant="h6_grey">
          Already have an account?{' '}
          <a className="purple-link" href="/login">
            Login
          </a>
        </Typography>
      </div>
    </Container>
  );
};
