import {useContext, useEffect, useState, useReducer} from 'react';

import {Grid, Skeleton} from '@mui/material';

import {Page} from '../../components';
import AppContext from '../../context/AppContext';
import {URLs} from '../../api';
import {ContestsViewContest, ContestsViewResponse} from '../../api/responses/ContestsViewResponseTypes';
import {ContestsTable} from './contests-table/ContestsTable';
import {request} from '../../api/API';

export const Contests = () => {
  const {dispatchError, user} = useContext(AppContext);

  const [contests, setContests] = useState<Array<ContestsViewContest> | undefined>(undefined);
  const [filteredContests, setFilteredContests] = useState<Array<ContestsViewContest>>([]);
  const [query, setQuery] = useState<string>('');
  // const [excludeEnded, setExcludeEnded] = useState<boolean>(false);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    request<{}, {}, ContestsViewResponse>(URLs.allContestsInActiveGroups, {
      method: 'GET',
      successCallback: (response) => {
        setContests(response.contests);
        forceUpdate();
      },
      errorCallback: () => {
        dispatchError('There was an error. Please try again later.', '/contests');
      },
    });
  }, [user]);

  useEffect(() => {
    setFilteredContests(contests ?? []);
  }, [contests]);

  useEffect(() => {
    setFilteredContests(
      query && query.trim() !== '' && contests
        ? contests.filter((contest) => contest.title.toLowerCase().includes(query.toLowerCase()))
        : contests ?? []
    );
  }, [query]);

  return (
    <>
      <Page>
        {!contests ? (
          <div className="flex flex-col gap-4">
            <Skeleton
              animation="pulse"
              variant="rectangular"
              sx={{height: '52px', width: '300px'}}
              className="self-end"
            />
            <Skeleton animation="pulse" variant="rectangular" sx={{height: '65vh', width: '90vw'}} />
          </div>
        ) : (
          <Grid container direction="column" sx={{minHeight: '65vh'}}>
            <div className="flex justify-between">
              <div className="flex, items-center gap-[5px]">
                {/*<p className='ml-[-4px]'> Exclude ended contests </p>
                <Switch checked={excludeEnded} onChange={() => setExcludeEnded(!excludeEnded)} /> */}
              </div>

              <div>
                <input
                  className="w-[300px] p-[15px] bg-card-main rounded outline-none text-white border-none"
                  placeholder="Search for a contest name"
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </div>
            <ContestsTable query={query} contests={filteredContests} />
          </Grid>
        )}
      </Page>
    </>
  );
};
