import {useContext, useEffect, useMemo, useState} from 'react';
import Select from 'react-select';

import {Button, Typography} from '@mui/material';

import {sendGetRequest, URLs} from '../../../api';
import AppContext from '../../../context/AppContext';
import {Page} from '../../../components';
import {Group} from '../../../types/entities/Group';
import {CurriculumProgress} from './CurriculumProgress';
import {GroupCurriculaEditingModal} from './GroupCurriculaEditingModal';
import {dropdownStylesConfig} from '../../../components/form/dropdown/Dropdown';
import {CurriculumGroupToContest} from '../../../api/responses/CurriculumResponseTypes';
import {ModuleType} from '../../../types/entities/ModuleType';

export const TrainerPage = () => {
  const dropdownStyles = dropdownStylesConfig();

  const [groups, setGroups] = useState<Array<Group>>([]);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [modules, setModules] = useState<Array<ModuleType>>([]);
  const {dispatchError, user} = useContext(AppContext);
  const [curriculumProgress, setCurriculumProgress] = useState<Array<CurriculumGroupToContest> | null>(null);
  const [showEditingModal, setShowEditingModal] = useState<boolean>(false);

  const getGroupProgress = () => {
    sendGetRequest(URLs.groupProgress(selectedGroup!.id))
      .then((response) => {
        setCurriculumProgress(response.data);
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  useEffect(() => {
    sendGetRequest(URLs.accessibleUserGroups)
      .then((response) => {
        const allAvailableGroups: Array<Group> = response.data || [];
        const activeGroups = user?.settings.activeGroups || [];

        let filteredGroups: Array<Group> = allAvailableGroups;

        if (activeGroups && activeGroups.length > 0) {
          filteredGroups = allAvailableGroups.filter((group) => activeGroups.includes(group.id));
        }

        if (filteredGroups.length === 1) {
          setSelectedGroup(filteredGroups[0]);
        }

        setGroups(filteredGroups);
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });

    sendGetRequest(URLs.getAllModules)
      .then((response) => {
        const modules: Array<ModuleType> = response.data;
        setModules(modules);
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      getGroupProgress();
    }
  }, [selectedGroup]);

  const groupOptions = useMemo(
    () => (groups ? groups.map((group) => ({value: group.id, label: group.name})) : []),
    [groups]
  );

  const handleHttpError = (err: any) => {
    if (err.response && err.response.data && err.response.data.error) {
      dispatchError(err.response.data.error, '/staff/curriculum');
    } else {
      dispatchError('There was an error. Please try again later', '/staff/curriculum');
    }
  };

  return (
    <div className="flex flex-col w-full h-full gap-2">
      <div className="flex flex-row items-center justify-between w-full">
        <div className="flex flex-row gap-2 items-center">
          <div className="flex w-96">
            <Select
              id="select-group"
              placeholder="Select group"
              defaultValue={groupOptions.find((option) => option.value === selectedGroup?.id)}
              onChange={(event) => {
                const selectedId: string = (event as {value: string; label: string}).value;
                const group = groups.filter((gr) => gr.id === Number(selectedId))[0];
                setSelectedGroup(group);
              }}
              options={groupOptions}
              styles={dropdownStyles}
              isDisabled={groupOptions.length === 1}
            />
          </div>
          {selectedGroup && (
            <Button className="ml-2" variant="contained" size="small" onClick={() => setShowEditingModal(true)}>
              Add new contest
            </Button>
          )}
        </div>
        {selectedGroup && modules && (
          <Typography variant="h6">
            Module: {modules.find((module) => module.id === selectedGroup.moduleId)?.module_name}
          </Typography>
        )}
      </div>
      {curriculumProgress !== null && (
        <CurriculumProgress
          curriculumProgress={curriculumProgress}
          handleHttpError={handleHttpError}
          onSuccess={() => getGroupProgress()}
        />
      )}
      {selectedGroup && showEditingModal && (
        <GroupCurriculaEditingModal
          groupId={selectedGroup.id}
          groupName={selectedGroup.name}
          moduleId={selectedGroup.moduleId}
          onClose={() => setShowEditingModal(false)}
          handleHttpError={handleHttpError}
          onSuccess={() => getGroupProgress()}
        />
      )}
    </div>
  );
};
